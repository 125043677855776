export const PACKAGE = { name: '@mui/utils', version: '5.16.8' };
export * from '@mui/utils';
export { internal_resolveProps as resolveProps } from '@mui/utils';
export { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/utils';
export { unstable_Timeout as Timeout } from '@mui/utils';
export { unstable_capitalize as capitalize } from '@mui/utils';
export { unstable_composeClasses as composeClasses } from '@mui/utils';
export { unstable_createChainedFunction as createChainedFunction } from '@mui/utils';
export { unstable_debounce as debounce } from '@mui/utils';
export { unstable_deprecatedPropType as deprecatedPropType } from '@mui/utils';
export { unstable_detectScrollType as detectScrollType } from '@mui/utils';
export { unstable_extractEventHandlers as extractEventHandlers } from '@mui/utils';
export { unstable_generateUtilityClass as generateUtilityClass } from '@mui/utils';
export { unstable_generateUtilityClasses as generateUtilityClasses } from '@mui/utils';
export { unstable_getNormalizedScrollLeft as getNormalizedScrollLeft } from '@mui/utils';
export { unstable_getReactElementRef as getReactElementRef } from '@mui/utils';
export { unstable_getScrollbarSize as getScrollbarSize } from '@mui/utils';
export { unstable_isGlobalState as isGlobalState } from '@mui/utils';
export { unstable_isMuiElement as isMuiElement } from '@mui/utils';
export { unstable_ownerDocument as ownerDocument } from '@mui/utils';
export { unstable_ownerWindow as ownerWindow } from '@mui/utils';
export { unstable_requirePropFactory as requirePropFactory } from '@mui/utils';
export { unstable_resolveComponentProps as resolveComponentProps } from '@mui/utils';
export { unstable_setRef as setRef } from '@mui/utils';
export { unstable_unsupportedProp as unsupportedProp } from '@mui/utils';
export { unstable_useControlled as useControlled } from '@mui/utils';
export { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
export { unstable_useEventCallback as useEventCallback } from '@mui/utils';
export { unstable_useForkRef as useForkRef } from '@mui/utils';
export { unstable_useId as useId } from '@mui/utils';
export { unstable_useIsFocusVisible as useIsFocusVisible } from '@mui/utils';
export { unstable_useLazyRef as useLazyRef } from '@mui/utils';
export { unstable_useOnMount as useOnMount } from '@mui/utils';
export { unstable_useSlotProps as useSlotProps } from '@mui/utils';
export { unstable_useTimeout as useTimeout } from '@mui/utils';
export * as 'appendOwnerState' from '@mui/utils/appendOwnerState/index.js';
export * as 'isHostComponent' from '@mui/utils/isHostComponent/index.js';
export * as 'mergeSlotProps' from '@mui/utils/mergeSlotProps/index.js';
export * as 'omitEventHandlers' from '@mui/utils/omitEventHandlers/index.js';
export * as 'scrollLeft' from '@mui/utils/scrollLeft/index.js';
export * as 'types' from '@mui/utils/types.js';
export * as 'useLocalStorageState' from '@mui/utils/useLocalStorageState/index.js';
import * as utils from '@mui/utils';

            const resolveImport = (itemName, subItemName) => {
                const item = utils[itemName];
                const subItem = utils[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('HTMLElementType', 'default');
resolveImport('chainPropTypes', 'default');
resolveImport('clamp', 'default');
resolveImport('deepmerge', 'default');
resolveImport('deepmerge', 'isPlainObject');
resolveImport('elementAcceptingRef', 'default');
resolveImport('elementTypeAcceptingRef', 'default');
resolveImport('exactProp', 'default');
resolveImport('formatMuiErrorMessage', 'default');
resolveImport('getDisplayName', 'default');
resolveImport('getDisplayName', 'getFunctionName');
resolveImport('getValidReactChildren', 'default');
resolveImport('integerPropType', 'default');
resolveImport('integerPropType', 'getTypeByValue');
resolveImport('internal_resolveProps', 'default');
resolveImport('ponyfillGlobal', 'default');
resolveImport('refType', 'default');
resolveImport('unstable_ClassNameGenerator', 'default');
resolveImport('unstable_capitalize', 'default');
resolveImport('unstable_composeClasses', 'default');
resolveImport('unstable_createChainedFunction', 'default');
resolveImport('unstable_debounce', 'default');
resolveImport('unstable_deprecatedPropType', 'default');
resolveImport('unstable_extractEventHandlers', 'default');
resolveImport('unstable_generateUtilityClass', 'default');
resolveImport('unstable_generateUtilityClass', 'globalStateClasses');
resolveImport('unstable_generateUtilityClass', 'isGlobalState');
resolveImport('unstable_generateUtilityClasses', 'default');
resolveImport('unstable_getReactElementRef', 'default');
resolveImport('unstable_getScrollbarSize', 'default');
resolveImport('unstable_isMuiElement', 'default');
resolveImport('unstable_ownerDocument', 'default');
resolveImport('unstable_ownerWindow', 'default');
resolveImport('unstable_requirePropFactory', 'default');
resolveImport('unstable_resolveComponentProps', 'default');
resolveImport('unstable_setRef', 'default');
resolveImport('unstable_unsupportedProp', 'default');
resolveImport('unstable_useControlled', 'default');
resolveImport('unstable_useEnhancedEffect', 'default');
resolveImport('unstable_useEventCallback', 'default');
resolveImport('unstable_useForkRef', 'default');
resolveImport('unstable_useId', 'default');
resolveImport('unstable_useIsFocusVisible', 'default');
resolveImport('unstable_useIsFocusVisible', 'teardown');
resolveImport('unstable_useLazyRef', 'default');
resolveImport('unstable_useOnMount', 'default');
resolveImport('unstable_useSlotProps', 'default');
resolveImport('unstable_useTimeout', 'Timeout');
resolveImport('unstable_useTimeout', 'default');
resolveImport('usePreviousProps', 'default');
resolveImport('visuallyHidden', 'default');