export const PACKAGE = { name: '@mui/private-theming', version: '5.16.8' };
export * from '@mui/private-theming';
export { unstable_nested as nested } from '@mui/private-theming';
import * as privateTheming from '@mui/private-theming';

            const resolveImport = (itemName, subItemName) => {
                const item = privateTheming[itemName];
                const subItem = privateTheming[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('ThemeProvider', 'default');
resolveImport('ThemeProvider', 'unstable_nested');
resolveImport('useTheme', 'default');