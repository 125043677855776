export const PACKAGE = { name: '@mui/material', version: '5.16.9' };
export * from '@mui/material';
export { unstable_composeClasses as composeClasses } from '@mui/material';
export { unstable_createMuiStrictModeTheme as createMuiStrictModeTheme } from '@mui/material';
export { unstable_getUnit as getUnit } from '@mui/material';
export { unstable_toUnitless as toUnitless } from '@mui/material';
export { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material';
export { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/material';
export { unstable_useId as useId } from '@mui/material';
export * as 'DefaultPropsProvider' from '@mui/material/DefaultPropsProvider/index.js';
export * as 'InitColorSchemeScript' from '@mui/material/InitColorSchemeScript/index.js';
export * as 'className' from '@mui/material/className/index.js';
export * as 'locale' from '@mui/material/locale/index.js';
export * as 'styles' from '@mui/material/styles/index.js';
export * as 'transitions' from '@mui/material/transitions/index.js';
export * as 'useTouchRipple' from '@mui/material/useTouchRipple/index.js';
export * as 'utils' from '@mui/material/utils/index.js';
export * as 'zero-styled' from '@mui/material/zero-styled/index.js';
import * as material from '@mui/material';

            const resolveImport = (itemName, subItemName) => {
                const item = material[itemName];
                const subItem = material[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('Accordion', 'accordionClasses');
resolveImport('Accordion', 'default');
resolveImport('Accordion', 'getAccordionUtilityClass');
resolveImport('AccordionActions', 'accordionActionsClasses');
resolveImport('AccordionActions', 'default');
resolveImport('AccordionActions', 'getAccordionActionsUtilityClass');
resolveImport('AccordionDetails', 'accordionDetailsClasses');
resolveImport('AccordionDetails', 'default');
resolveImport('AccordionDetails', 'getAccordionDetailsUtilityClass');
resolveImport('AccordionSummary', 'accordionSummaryClasses');
resolveImport('AccordionSummary', 'default');
resolveImport('AccordionSummary', 'getAccordionSummaryUtilityClass');
resolveImport('Alert', 'alertClasses');
resolveImport('Alert', 'default');
resolveImport('Alert', 'getAlertUtilityClass');
resolveImport('AlertTitle', 'alertTitleClasses');
resolveImport('AlertTitle', 'default');
resolveImport('AlertTitle', 'getAlertTitleUtilityClass');
resolveImport('AppBar', 'appBarClasses');
resolveImport('AppBar', 'default');
resolveImport('AppBar', 'getAppBarUtilityClass');
resolveImport('Autocomplete', 'autocompleteClasses');
resolveImport('Autocomplete', 'createFilterOptions');
resolveImport('Autocomplete', 'default');
resolveImport('Autocomplete', 'getAutocompleteUtilityClass');
resolveImport('Avatar', 'avatarClasses');
resolveImport('Avatar', 'default');
resolveImport('Avatar', 'getAvatarUtilityClass');
resolveImport('AvatarGroup', 'avatarGroupClasses');
resolveImport('AvatarGroup', 'default');
resolveImport('AvatarGroup', 'getAvatarGroupUtilityClass');
resolveImport('Backdrop', 'backdropClasses');
resolveImport('Backdrop', 'default');
resolveImport('Backdrop', 'getBackdropUtilityClass');
resolveImport('Badge', 'badgeClasses');
resolveImport('Badge', 'default');
resolveImport('Badge', 'getBadgeUtilityClass');
resolveImport('BottomNavigation', 'bottomNavigationClasses');
resolveImport('BottomNavigation', 'default');
resolveImport('BottomNavigation', 'getBottomNavigationUtilityClass');
resolveImport('BottomNavigationAction', 'bottomNavigationActionClasses');
resolveImport('BottomNavigationAction', 'default');
resolveImport('BottomNavigationAction', 'getBottomNavigationActionUtilityClass');
resolveImport('Box', 'boxClasses');
resolveImport('Box', 'default');
resolveImport('Breadcrumbs', 'breadcrumbsClasses');
resolveImport('Breadcrumbs', 'default');
resolveImport('Breadcrumbs', 'getBreadcrumbsUtilityClass');
resolveImport('Button', 'buttonClasses');
resolveImport('Button', 'default');
resolveImport('Button', 'getButtonUtilityClass');
resolveImport('ButtonBase', 'buttonBaseClasses');
resolveImport('ButtonBase', 'default');
resolveImport('ButtonBase', 'touchRippleClasses');
resolveImport('ButtonBase', 'getButtonBaseUtilityClass');
resolveImport('ButtonBase', 'getTouchRippleUtilityClass');
resolveImport('ButtonGroup', 'ButtonGroupButtonContext');
resolveImport('ButtonGroup', 'ButtonGroupContext');
resolveImport('ButtonGroup', 'buttonGroupClasses');
resolveImport('ButtonGroup', 'default');
resolveImport('ButtonGroup', 'getButtonGroupUtilityClass');
resolveImport('Card', 'cardClasses');
resolveImport('Card', 'default');
resolveImport('Card', 'getCardUtilityClass');
resolveImport('CardActionArea', 'cardActionAreaClasses');
resolveImport('CardActionArea', 'default');
resolveImport('CardActionArea', 'getCardActionAreaUtilityClass');
resolveImport('CardActions', 'cardActionsClasses');
resolveImport('CardActions', 'default');
resolveImport('CardActions', 'getCardActionsUtilityClass');
resolveImport('CardContent', 'cardContentClasses');
resolveImport('CardContent', 'default');
resolveImport('CardContent', 'getCardContentUtilityClass');
resolveImport('CardHeader', 'cardHeaderClasses');
resolveImport('CardHeader', 'default');
resolveImport('CardHeader', 'getCardHeaderUtilityClass');
resolveImport('CardMedia', 'cardMediaClasses');
resolveImport('CardMedia', 'default');
resolveImport('CardMedia', 'getCardMediaUtilityClass');
resolveImport('Checkbox', 'checkboxClasses');
resolveImport('Checkbox', 'default');
resolveImport('Checkbox', 'getCheckboxUtilityClass');
resolveImport('Chip', 'chipClasses');
resolveImport('Chip', 'default');
resolveImport('Chip', 'getChipUtilityClass');
resolveImport('CircularProgress', 'circularProgressClasses');
resolveImport('CircularProgress', 'default');
resolveImport('CircularProgress', 'getCircularProgressUtilityClass');
resolveImport('ClickAwayListener', 'default');
resolveImport('Collapse', 'collapseClasses');
resolveImport('Collapse', 'default');
resolveImport('Collapse', 'getCollapseUtilityClass');
resolveImport('Container', 'containerClasses');
resolveImport('Container', 'default');
resolveImport('Container', 'getContainerUtilityClass');
resolveImport('CssBaseline', 'default');
resolveImport('Dialog', 'default');
resolveImport('Dialog', 'dialogClasses');
resolveImport('Dialog', 'getDialogUtilityClass');
resolveImport('DialogActions', 'default');
resolveImport('DialogActions', 'dialogActionsClasses');
resolveImport('DialogActions', 'getDialogActionsUtilityClass');
resolveImport('DialogContent', 'default');
resolveImport('DialogContent', 'dialogContentClasses');
resolveImport('DialogContent', 'getDialogContentUtilityClass');
resolveImport('DialogContentText', 'default');
resolveImport('DialogContentText', 'dialogContentTextClasses');
resolveImport('DialogContentText', 'getDialogContentTextUtilityClass');
resolveImport('DialogTitle', 'default');
resolveImport('DialogTitle', 'dialogTitleClasses');
resolveImport('DialogTitle', 'getDialogTitleUtilityClass');
resolveImport('Divider', 'default');
resolveImport('Divider', 'dividerClasses');
resolveImport('Divider', 'getDividerUtilityClass');
resolveImport('Drawer', 'default');
resolveImport('Drawer', 'drawerClasses');
resolveImport('Drawer', 'getDrawerUtilityClass');
resolveImport('Fab', 'default');
resolveImport('Fab', 'fabClasses');
resolveImport('Fab', 'getFabUtilityClass');
resolveImport('Fade', 'default');
resolveImport('FilledInput', 'default');
resolveImport('FilledInput', 'filledInputClasses');
resolveImport('FilledInput', 'getFilledInputUtilityClass');
resolveImport('FormControl', 'default');
resolveImport('FormControl', 'formControlClasses');
resolveImport('FormControl', 'useFormControl');
resolveImport('FormControl', 'getFormControlUtilityClasses');
resolveImport('FormControlLabel', 'default');
resolveImport('FormControlLabel', 'formControlLabelClasses');
resolveImport('FormControlLabel', 'getFormControlLabelUtilityClasses');
resolveImport('FormGroup', 'default');
resolveImport('FormGroup', 'formGroupClasses');
resolveImport('FormGroup', 'getFormGroupUtilityClass');
resolveImport('FormHelperText', 'default');
resolveImport('FormHelperText', 'formHelperTextClasses');
resolveImport('FormHelperText', 'getFormHelperTextUtilityClasses');
resolveImport('FormLabel', 'default');
resolveImport('FormLabel', 'formLabelClasses');
resolveImport('FormLabel', 'FormLabelRoot');
resolveImport('FormLabel', 'getFormLabelUtilityClasses');
resolveImport('GlobalStyles', 'default');
resolveImport('Grid', 'default');
resolveImport('Grid', 'gridClasses');
resolveImport('Grid', 'getGridUtilityClass');
resolveImport('Grow', 'default');
resolveImport('Hidden', 'default');
resolveImport('Icon', 'default');
resolveImport('Icon', 'iconClasses');
resolveImport('Icon', 'getIconUtilityClass');
resolveImport('IconButton', 'default');
resolveImport('IconButton', 'iconButtonClasses');
resolveImport('IconButton', 'getIconButtonUtilityClass');
resolveImport('ImageList', 'default');
resolveImport('ImageList', 'imageListClasses');
resolveImport('ImageList', 'getImageListUtilityClass');
resolveImport('ImageListItem', 'default');
resolveImport('ImageListItem', 'imageListItemClasses');
resolveImport('ImageListItem', 'getImageListItemUtilityClass');
resolveImport('ImageListItemBar', 'default');
resolveImport('ImageListItemBar', 'imageListItemBarClasses');
resolveImport('ImageListItemBar', 'getImageListItemBarUtilityClass');
resolveImport('Input', 'default');
resolveImport('Input', 'inputClasses');
resolveImport('Input', 'getInputUtilityClass');
resolveImport('InputAdornment', 'default');
resolveImport('InputAdornment', 'inputAdornmentClasses');
resolveImport('InputAdornment', 'getInputAdornmentUtilityClass');
resolveImport('InputBase', 'default');
resolveImport('InputBase', 'inputBaseClasses');
resolveImport('InputBase', 'getInputBaseUtilityClass');
resolveImport('InputLabel', 'default');
resolveImport('InputLabel', 'inputLabelClasses');
resolveImport('InputLabel', 'getInputLabelUtilityClasses');
resolveImport('LinearProgress', 'default');
resolveImport('LinearProgress', 'linearProgressClasses');
resolveImport('LinearProgress', 'getLinearProgressUtilityClass');
resolveImport('Link', 'default');
resolveImport('Link', 'linkClasses');
resolveImport('Link', 'getLinkUtilityClass');
resolveImport('List', 'default');
resolveImport('List', 'listClasses');
resolveImport('List', 'getListUtilityClass');
resolveImport('ListItem', 'default');
resolveImport('ListItem', 'listItemClasses');
resolveImport('ListItem', 'getListItemUtilityClass');
resolveImport('ListItemAvatar', 'default');
resolveImport('ListItemAvatar', 'listItemAvatarClasses');
resolveImport('ListItemAvatar', 'getListItemAvatarUtilityClass');
resolveImport('ListItemButton', 'default');
resolveImport('ListItemButton', 'listItemButtonClasses');
resolveImport('ListItemButton', 'getListItemButtonUtilityClass');
resolveImport('ListItemIcon', 'default');
resolveImport('ListItemIcon', 'listItemIconClasses');
resolveImport('ListItemIcon', 'getListItemIconUtilityClass');
resolveImport('ListItemSecondaryAction', 'default');
resolveImport('ListItemSecondaryAction', 'listItemSecondaryActionClasses');
resolveImport('ListItemSecondaryAction', 'getListItemSecondaryActionClassesUtilityClass');
resolveImport('ListItemText', 'default');
resolveImport('ListItemText', 'listItemTextClasses');
resolveImport('ListItemText', 'getListItemTextUtilityClass');
resolveImport('ListSubheader', 'default');
resolveImport('ListSubheader', 'listSubheaderClasses');
resolveImport('ListSubheader', 'getListSubheaderUtilityClass');
resolveImport('Menu', 'default');
resolveImport('Menu', 'menuClasses');
resolveImport('Menu', 'getMenuUtilityClass');
resolveImport('MenuItem', 'default');
resolveImport('MenuItem', 'menuItemClasses');
resolveImport('MenuItem', 'getMenuItemUtilityClass');
resolveImport('MenuList', 'default');
resolveImport('MobileStepper', 'default');
resolveImport('MobileStepper', 'mobileStepperClasses');
resolveImport('MobileStepper', 'getMobileStepperUtilityClass');
resolveImport('Modal', 'ModalManager');
resolveImport('Modal', 'default');
resolveImport('Modal', 'modalClasses');
resolveImport('Modal', 'getModalUtilityClass');
resolveImport('NativeSelect', 'default');
resolveImport('NativeSelect', 'nativeSelectClasses');
resolveImport('NativeSelect', 'getNativeSelectUtilityClasses');
resolveImport('NoSsr', 'default');
resolveImport('OutlinedInput', 'default');
resolveImport('OutlinedInput', 'outlinedInputClasses');
resolveImport('OutlinedInput', 'getOutlinedInputUtilityClass');
resolveImport('Pagination', 'default');
resolveImport('Pagination', 'paginationClasses');
resolveImport('Pagination', 'getPaginationUtilityClass');
resolveImport('PaginationItem', 'default');
resolveImport('PaginationItem', 'paginationItemClasses');
resolveImport('PaginationItem', 'getPaginationItemUtilityClass');
resolveImport('Paper', 'default');
resolveImport('Paper', 'paperClasses');
resolveImport('Paper', 'getPaperUtilityClass');
resolveImport('Popover', 'default');
resolveImport('Popover', 'popoverClasses');
resolveImport('Popover', 'PopoverPaper');
resolveImport('Popover', 'PopoverRoot');
resolveImport('Popover', 'getOffsetLeft');
resolveImport('Popover', 'getOffsetTop');
resolveImport('Popover', 'getPopoverUtilityClass');
resolveImport('Popper', 'default');
resolveImport('Popper', 'getPopperUtilityClass');
resolveImport('Portal', 'default');
resolveImport('Radio', 'default');
resolveImport('Radio', 'radioClasses');
resolveImport('Radio', 'getRadioUtilityClass');
resolveImport('RadioGroup', 'default');
resolveImport('RadioGroup', 'radioGroupClasses');
resolveImport('RadioGroup', 'useRadioGroup');
resolveImport('RadioGroup', 'getRadioGroupUtilityClass');
resolveImport('Rating', 'default');
resolveImport('Rating', 'ratingClasses');
resolveImport('Rating', 'getRatingUtilityClass');
resolveImport('ScopedCssBaseline', 'default');
resolveImport('ScopedCssBaseline', 'scopedCssBaselineClasses');
resolveImport('ScopedCssBaseline', 'getScopedCssBaselineUtilityClass');
resolveImport('Select', 'default');
resolveImport('Select', 'selectClasses');
resolveImport('Select', 'getSelectUtilityClasses');
resolveImport('Skeleton', 'default');
resolveImport('Skeleton', 'skeletonClasses');
resolveImport('Skeleton', 'getSkeletonUtilityClass');
resolveImport('Slide', 'default');
resolveImport('Slider', 'default');
resolveImport('Slider', 'sliderClasses');
resolveImport('Slider', 'SliderMark');
resolveImport('Slider', 'SliderMarkLabel');
resolveImport('Slider', 'SliderRail');
resolveImport('Slider', 'SliderRoot');
resolveImport('Slider', 'SliderThumb');
resolveImport('Slider', 'SliderTrack');
resolveImport('Slider', 'SliderValueLabel');
resolveImport('Slider', 'getSliderUtilityClass');
resolveImport('Snackbar', 'default');
resolveImport('Snackbar', 'snackbarClasses');
resolveImport('Snackbar', 'getSnackbarUtilityClass');
resolveImport('SnackbarContent', 'default');
resolveImport('SnackbarContent', 'snackbarContentClasses');
resolveImport('SnackbarContent', 'getSnackbarContentUtilityClass');
resolveImport('SpeedDial', 'default');
resolveImport('SpeedDial', 'speedDialClasses');
resolveImport('SpeedDial', 'getSpeedDialUtilityClass');
resolveImport('SpeedDialAction', 'default');
resolveImport('SpeedDialAction', 'speedDialActionClasses');
resolveImport('SpeedDialAction', 'getSpeedDialActionUtilityClass');
resolveImport('SpeedDialIcon', 'default');
resolveImport('SpeedDialIcon', 'speedDialIconClasses');
resolveImport('SpeedDialIcon', 'getSpeedDialIconUtilityClass');
resolveImport('Stack', 'default');
resolveImport('Stack', 'stackClasses');
resolveImport('Step', 'StepContext');
resolveImport('Step', 'default');
resolveImport('Step', 'stepClasses');
resolveImport('Step', 'getStepUtilityClass');
resolveImport('Step', 'useStepContext');
resolveImport('StepButton', 'default');
resolveImport('StepButton', 'stepButtonClasses');
resolveImport('StepButton', 'getStepButtonUtilityClass');
resolveImport('StepConnector', 'default');
resolveImport('StepConnector', 'stepConnectorClasses');
resolveImport('StepConnector', 'getStepConnectorUtilityClass');
resolveImport('StepContent', 'default');
resolveImport('StepContent', 'stepContentClasses');
resolveImport('StepContent', 'getStepContentUtilityClass');
resolveImport('StepIcon', 'default');
resolveImport('StepIcon', 'stepIconClasses');
resolveImport('StepIcon', 'getStepIconUtilityClass');
resolveImport('StepLabel', 'default');
resolveImport('StepLabel', 'stepLabelClasses');
resolveImport('StepLabel', 'getStepLabelUtilityClass');
resolveImport('Stepper', 'StepperContext');
resolveImport('Stepper', 'default');
resolveImport('Stepper', 'stepperClasses');
resolveImport('Stepper', 'getStepperUtilityClass');
resolveImport('Stepper', 'useStepperContext');
resolveImport('SvgIcon', 'default');
resolveImport('SvgIcon', 'svgIconClasses');
resolveImport('SvgIcon', 'getSvgIconUtilityClass');
resolveImport('SwipeableDrawer', 'default');
resolveImport('Switch', 'default');
resolveImport('Switch', 'switchClasses');
resolveImport('Switch', 'getSwitchUtilityClass');
resolveImport('Tab', 'default');
resolveImport('Tab', 'tabClasses');
resolveImport('Tab', 'getTabUtilityClass');
resolveImport('TabScrollButton', 'default');
resolveImport('TabScrollButton', 'tabScrollButtonClasses');
resolveImport('TabScrollButton', 'getTabScrollButtonUtilityClass');
resolveImport('Table', 'default');
resolveImport('Table', 'tableClasses');
resolveImport('Table', 'getTableUtilityClass');
resolveImport('TableBody', 'default');
resolveImport('TableBody', 'tableBodyClasses');
resolveImport('TableBody', 'getTableBodyUtilityClass');
resolveImport('TableCell', 'default');
resolveImport('TableCell', 'tableCellClasses');
resolveImport('TableCell', 'getTableCellUtilityClass');
resolveImport('TableContainer', 'default');
resolveImport('TableContainer', 'tableContainerClasses');
resolveImport('TableContainer', 'getTableContainerUtilityClass');
resolveImport('TableFooter', 'default');
resolveImport('TableFooter', 'tableFooterClasses');
resolveImport('TableFooter', 'getTableFooterUtilityClass');
resolveImport('TableHead', 'default');
resolveImport('TableHead', 'tableHeadClasses');
resolveImport('TableHead', 'getTableHeadUtilityClass');
resolveImport('TablePagination', 'default');
resolveImport('TablePagination', 'tablePaginationClasses');
resolveImport('TablePagination', 'getTablePaginationUtilityClass');
resolveImport('TableRow', 'default');
resolveImport('TableRow', 'tableRowClasses');
resolveImport('TableRow', 'getTableRowUtilityClass');
resolveImport('TableSortLabel', 'default');
resolveImport('TableSortLabel', 'tableSortLabelClasses');
resolveImport('TableSortLabel', 'getTableSortLabelUtilityClass');
resolveImport('Tabs', 'default');
resolveImport('Tabs', 'tabsClasses');
resolveImport('Tabs', 'getTabsUtilityClass');
resolveImport('TextField', 'default');
resolveImport('TextField', 'textFieldClasses');
resolveImport('TextField', 'getTextFieldUtilityClass');
resolveImport('TextareaAutosize', 'default');
resolveImport('ToggleButton', 'default');
resolveImport('ToggleButton', 'toggleButtonClasses');
resolveImport('ToggleButton', 'getToggleButtonUtilityClass');
resolveImport('ToggleButtonGroup', 'default');
resolveImport('ToggleButtonGroup', 'toggleButtonGroupClasses');
resolveImport('ToggleButtonGroup', 'getToggleButtonGroupUtilityClass');
resolveImport('Toolbar', 'default');
resolveImport('Toolbar', 'toolbarClasses');
resolveImport('Toolbar', 'getToolbarUtilityClass');
resolveImport('Tooltip', 'default');
resolveImport('Tooltip', 'tooltipClasses');
resolveImport('Tooltip', 'getTooltipUtilityClass');
resolveImport('Typography', 'default');
resolveImport('Typography', 'typographyClasses');
resolveImport('Typography', 'getTypographyUtilityClass');
resolveImport('Unstable_Grid2', 'default');
resolveImport('Unstable_Grid2', 'grid2Classes');
resolveImport('Unstable_Grid2', 'getGrid2UtilityClass');
resolveImport('Unstable_TrapFocus', 'default');
resolveImport('Zoom', 'default');
resolveImport('colors', 'amber');
resolveImport('colors', 'blue');
resolveImport('colors', 'blueGrey');
resolveImport('colors', 'brown');
resolveImport('colors', 'common');
resolveImport('colors', 'cyan');
resolveImport('colors', 'deepOrange');
resolveImport('colors', 'deepPurple');
resolveImport('colors', 'green');
resolveImport('colors', 'grey');
resolveImport('colors', 'indigo');
resolveImport('colors', 'lightBlue');
resolveImport('colors', 'lightGreen');
resolveImport('colors', 'lime');
resolveImport('colors', 'orange');
resolveImport('colors', 'pink');
resolveImport('colors', 'purple');
resolveImport('colors', 'red');
resolveImport('colors', 'teal');
resolveImport('colors', 'yellow');
resolveImport('darkScrollbar', 'default');
resolveImport('generateUtilityClass', 'default');
resolveImport('generateUtilityClasses', 'default');
resolveImport('useAutocomplete', 'createFilterOptions');
resolveImport('useAutocomplete', 'default');
resolveImport('useMediaQuery', 'default');
resolveImport('usePagination', 'default');
resolveImport('useScrollTrigger', 'default');
resolveImport('StyledEngineProvider', 'default');
resolveImport('version', 'default');
resolveImport('version', 'major');
resolveImport('version', 'minor');
resolveImport('version', 'patch');
resolveImport('version', 'preReleaseLabel');
resolveImport('version', 'preReleaseNumber');
resolveImport('version', 'version');